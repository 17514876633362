import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'

import {
  FaTags,
} from 'react-icons/fa';

const TagsPage = ({
  data: { allMarkdownRemark: { group }, site: { siteMetadata: { title } } },
}) => (
    <Layout>
      <Helmet title={`Tags | ${title}`} />
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-vcentered'>

              <div className='column is-12-mobile is-6' >
                <div className='section'>
                  <h1 className='title has-text-weight-semibold is-size-3'>Tags</h1>
                </div>
              </div>

              <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
                <figure className="image is4by3 mt-2">
                  <FaTags size={340} color={'#f7e151'} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container content'>
          <div className='columns'>
            <div
              className='column is-10 is-offset-1'
              style={{ marginBottom: '6rem' }}
            >
              <ul className='taglist'>
                {group.map(tag => (
                  <li key={tag.fieldValue}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                      {tag.fieldValue} ({tag.totalCount})
                  </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
